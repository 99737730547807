import { Button, Card } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "./FreePlanRedeemSuccessPage.module.sass";

const FreePlanRedeemSuccessPage = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.button}>
        <Card title="付款成功" style={{ width: 300 }}>
          <p>免費方案兌換成功，點擊以下按鈕返回首頁</p>
          <Button
            type="primary"
            ghost
            onClick={() => navigate("/", { replace: true })}
          >
            <div className={classes.buttonWord}>回到首頁</div>
          </Button>
        </Card>
      </div>
    </div>
  );
};
export default FreePlanRedeemSuccessPage;
