import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "../Footer.module.sass";
import { updateMe } from "../../../api/me";
import { useAppStateStore } from "../../../store/appState";
import { useNameCardFromValidate } from "../../../context/NameCardFromValidate";

const ProfileFooter = () => {
  const navigate = useNavigate();
  const { USER, PROFILE_PAGE, APP } = useAppStateStore();
  const { setAppState } = APP;
  const { setUserState, userState } = USER;
  const { accessToken, role } = userState;

  const { setProfileInputData, profileInputData, initProfileInputData } =
    PROFILE_PAGE;
  const {
    isValidateEmail,
    isValidateName,
    isValidatePhoneNumber,
    isValidateBankCode,
    isValidateBankAccount,
    bank_code,
    bank_account,
  } = profileInputData;
  const { setMobileNumber, setIsValidateAll } = useNameCardFromValidate(); // 24.11.07 SCH ★
  const { setRealName, setEmailUrl, setPromoSlug } = useNameCardFromValidate();

  const updateMeData = async () => {
    try {
      setAppState({ isLoading: true });
      if (accessToken && profileInputData) {
        console.log("profileInputData Footer", profileInputData);
        try {
          const { data: res, error: meError } = await updateMe(
            accessToken,
            profileInputData
          );
          if (res) {
            const { data } = res;
            if (res) {
              const { data: updateMeResData } = data;
              setUserState({ ...updateMeResData, user_id: updateMeResData.id });
            }
          }
          setAppState({ isLoading: false });
          // initProfileInputData(); // 24.11.06 SCH ★

          // 24.11.06 By RichardSu ★
          if (!meError) {
            navigate(`/`); // 24.11.06 SCH ★
            initProfileInputData(); // 24.11.06 SCH ★
          } else {
            console.log("updateMe", meError);
            const keepData = {
              mobile_number: profileInputData.mobile_number,
              email: profileInputData.email,
              realname: profileInputData.realname,
              promo_slug: profileInputData.promo_slug,
              isValidateAll: false,
            };
            console.log("updateMe.keepData", keepData);
            setRealName(profileInputData.realname ?? "");
            setMobileNumber(profileInputData.mobile_number ?? "");
            setEmailUrl(profileInputData.email ?? ""); // 24.11.07 SCH ★★★
            setPromoSlug(profileInputData.promo_slug ?? "");

            if (meError.response?.status === 422) {
              const { message: errorMsg } = meError.response
                .data as unknown as { message: string };
              if (errorMsg === "The mobile number has already been taken.") {
                console.log("updateMe.422", errorMsg);
                setProfileInputData({
                  mobile_number: profileInputData.mobile_number,
                }); // 24.11.07 SCH ★
                // setProfileInputData({ isValidatePhoneNumber : false}); // 24.11.07 SCH ★
                setIsValidateAll(false); // 24.11.07 SCH ★★★
                console.log("profileInputData Footer-1", profileInputData);
                setTimeout(() => {
                  // window.location.reload(); // 24.11.07 SCH ★ (relogin)
                }, 2500); // 停留(2.5)秒後再執行:window.location.reload()！
                return message.warn("儲存失敗，手機號碼已被他人使用"); // 24.11.06 SCH ★
              }
            }
            if (meError.response?.status === 403) {
              const { message: errorMsg } = meError.response
                .data as unknown as { message: string };
              if (errorMsg === "The promo_slug has existed.") {
                console.log("updateMe.403", errorMsg);
                setProfileInputData({
                  promo_slug: profileInputData.promo_slug,
                }); // 24.11.07 SCH ★
                // setProfileInputData({ isValidatePromoSlug : false}); // 24.11.07 SCH ★
                console.log("profileInputData Footer-2", profileInputData);
                setIsValidateAll(false); // 24.11.07 SCH ★★★
                setTimeout(() => {
                  // window.location.reload(); // 24.11.07 SCH ★ (relogin)
                }, 2500); // 停留(2.5)秒後再執行:window.location.reload()！
                return message.warn("（自訂）連結推廣碼已被他人佔用"); // 24.11.06 SCH ★
              }
            }
          }
        } catch (err422) {
          console.log("updateMe Error 422 -", err422); // 24.11.06 SCH ★
        }
      }
    } catch (error) {
      setAppState({
        isLoading: false,
        isError: true,
        errorMsg: `updateMeData Error -, ${error}`,
      });
      console.log("updateMeData Error -", error);
    }
  };

  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={() => {
            initProfileInputData();
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>取消</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.button}
          disabled={
            !(isValidateEmail && isValidateName && isValidatePhoneNumber) ||
            (role >= 2 &&
              ((bank_code?.trim() !== "" && !isValidateBankCode) ||
                (bank_account?.trim() !== "" && !isValidateBankAccount))) // 當 role > = 2 是付費會員時，有填 bank_code 和 bank_account 才會檢查
          }
          onClick={() => {
            updateMeData();
            // navigate(`/`); // 24.11.06 SCH ★
          }}
        >
          <div className={classes.buttonWord}>儲存</div>
        </Button>
      </div>
    </div>
  );
};

export default ProfileFooter;
