import { Form, Input, Row, Col, Button } from "antd";
import { useEffect, useState } from "react";
import classes from "./PhoneNumberButton.module.sass";
import { IphoneButton } from "../../../../interface/flexMessage";
import { IPhoneNumberButtonProps } from "./Type";
import { useNameCardFromValidate } from "../../../../context/NameCardFromValidate";
import {
  useAppStateStore,
  nameCardV1DataType,
} from "../../../../store/appState";

const PhoneNumberButton = ({
  _index,
  thisPageFlexMessageIndex,
}: IPhoneNumberButtonProps) => {
  const { NAMECARD_DATA } = useAppStateStore();
  const { nameCardData, setNameCardV1Data } = NAMECARD_DATA;
  const { nameCardV1Data } = nameCardData;
  const [isValidatePhoneNumber, setIsValidatePhoneNumber] =
    useState<boolean>(false);
  const { hasValidateButton, setHasValidateButton } = useNameCardFromValidate();

  const handlePhoneNumChange = (value: string) => {
    if (nameCardV1Data && thisPageFlexMessageIndex !== undefined) {
      const _flexMessage: nameCardV1DataType[] = Array.from(nameCardV1Data);
      const _content = _flexMessage[thisPageFlexMessageIndex].button[_index]
        .content as IphoneButton;
      _content.phoneNumber = value.trim();
      _flexMessage[thisPageFlexMessageIndex].button[_index].content = _content;
      setNameCardV1Data(_flexMessage);
    }
  };
  const validatePhoneNumber = (value: string) => {
    if (value && value.length === 10 && value[0] === "0" && value[1] === "9") {
      setIsValidatePhoneNumber(true);
      addCurrentValidateUrl(true, _index);
    } else {
      setIsValidatePhoneNumber(false);
      addCurrentValidateUrl(false, _index);
    }
  };

  useEffect(() => {
    if (nameCardV1Data) {
      validatePhoneNumber(
        (
          nameCardV1Data[thisPageFlexMessageIndex].button[_index]
            .content as IphoneButton
        ).phoneNumber
      );
    }
  }, [
    nameCardV1Data &&
      nameCardV1Data[thisPageFlexMessageIndex].button[_index].content,
  ]);

  const remove = (_index: number) => {
    if (nameCardV1Data) {
      const _flexMessage = Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndex].button.splice(_index, 1);
      setNameCardV1Data(_flexMessage);
      removeCurrentValidateUrl(_index);
    }
  };

  const addCurrentValidateUrl = (_validate: boolean, elementIndex: number) => {
    console.log("elementIndex", elementIndex);

    const _temp = Array.from(hasValidateButton);
    if (_temp[elementIndex] !== undefined) {
      _temp[elementIndex] = _validate;
      console.log("dsdsdsd");
    } else {
      _temp.push(_validate);
    }
    setHasValidateButton(_temp);
  };

  const removeCurrentValidateUrl = (elementIndex: number) => {
    const _temp = Array.from(hasValidateButton);
    console.log("!!!!!!", _temp[elementIndex]);

    if (_temp[elementIndex] !== undefined) {
      _temp.splice(elementIndex, 1);
      setHasValidateButton(_temp);
    }
  };

  return (
    <Row justify="center" align="middle" gutter={[8, 8]}>
      <Col span={24}>
        <div className={classes.content}>
          <Form.Item
            className={classes.col}
            key={_index}
            label={<span className={classes.label}>手機號碼</span>}
            validateStatus={isValidatePhoneNumber ? "success" : "error"}
            hasFeedback
          >
            <Input
              type="tel"
              placeholder="請輸入名片手機號碼"
              className={classes.phoneNum}
              onChange={(e) => {
                validatePhoneNumber(e.target.value);
                handlePhoneNumChange(e.target.value);
              }}
              value={
                nameCardV1Data === null
                  ? undefined
                  : {
                      ...(nameCardV1Data[thisPageFlexMessageIndex ?? 0].button[
                        _index
                      ].content as IphoneButton),
                    }.phoneNumber
              }
            />
          </Form.Item>
          <div>
            <Button
              size="small"
              className={classes.button}
              onClick={() => remove(_index)}
            >
              X
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default PhoneNumberButton;
