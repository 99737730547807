import classes from "./NameCardTypeModal.module.sass";
import { Modal, Button } from "antd";
import NameCardV1Img from "../../../image/NameCardV1.0.png";
import NameCardV3Img from "../../../image/NameCardV3.0.png";

import { useNavigate } from "react-router-dom";
import { useAppStateStore } from "../../../store/appState";

interface INameCardTypeModalProps {
  isOpen: boolean;
  closeNameCardTypeModalHandler: () => void;
}

const NameCardTypeModal = ({
  isOpen,
  closeNameCardTypeModalHandler,
}: INameCardTypeModalProps) => {
  const navigate = useNavigate();
  const { NAMECARD_OPERATION } = useAppStateStore();
  const { nameCardOperationState, setNameCardOperationState } =
    NAMECARD_OPERATION;
  const { selectNameCardType } = nameCardOperationState;

  const createNameCardHandler = () => {
    if (selectNameCardType === "null") return;
    if (selectNameCardType === "v1.0") {
      setNameCardOperationState({
        currentNameCardType: "v1.0",
        action: "create",
      });
      navigate("/createNameCard");
    } else if (selectNameCardType === "v3.0") {
      setNameCardOperationState({
        currentNameCardType: "v3.0",
        action: "create",
      });
      navigate("/createFullImgMode");
    }

    closeNameCardTypeModalHandler();
  };

  return (
    <div>
      <Modal
        onCancel={() => {
          closeNameCardTypeModalHandler();
          navigate("/");
        }}
        title={<div className={classes.modalTitle}>請選擇名片類型</div>}
        open={isOpen}
        className={classes.modalStyle}
        footer={
          <div className={classes.footerControl}>
            <div className={classes.footerButtonItem}>
              <Button
                className={`${classes.footerButton} ${classes.buttonBlueColor}`}
                type="default"
              >
                <div
                  className={classes.buttonWord}
                  onClick={() => {
                    closeNameCardTypeModalHandler();
                    navigate("/");
                  }}
                >
                  取消
                </div>
              </Button>
            </div>
            <div className={classes.footerButtonItem}>
              <Button
                className={`${classes.footerButton} ${classes.buttonBlueBg}`}
                type="primary"
                onClick={createNameCardHandler}
              >
                <div className={classes.buttonWord}>建立</div>
              </Button>
            </div>
          </div>
        }
      >
        <div className={classes.contentContainer}>
          <div
            className={`${classes.nameCardTypeItem} ${
              selectNameCardType === "v1.0" ? classes.selected : ""
            }`}
            onClick={() =>
              setNameCardOperationState({ selectNameCardType: "v1.0" })
            }
          >
            <div className={classes.imgItem}>
              <img src={NameCardV1Img} alt="" />
            </div>
            <div className={classes.nameCardTypeItemText}>圖片與文字</div>
          </div>
          <div
            className={`${classes.nameCardTypeItem} ${
              selectNameCardType === "v3.0" ? classes.selected : ""
            }`}
            onClick={() =>
              setNameCardOperationState({ selectNameCardType: "v3.0" })
            }
          >
            <div className={classes.imgItem}>
              <img src={NameCardV3Img} alt="" />
            </div>
            <div className={classes.nameCardTypeItemText}>滿版圖片</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default NameCardTypeModal;
