import React, { createContext, useContext, useState } from "react";

interface IHasValidateProfile {
  profileEmailValidate: boolean;
  profilePhonelValidate: boolean;
}
interface INameCardFromValidate {
  hasValidateButton: boolean[];
  setHasValidateButton: React.Dispatch<React.SetStateAction<boolean[]>>;
  hasValidateImageUrl: boolean[];
  setHasValidateImageUrl: React.Dispatch<React.SetStateAction<boolean[]>>;
  validateEmailProfile: boolean;
  setValidateEmailProfile: React.Dispatch<React.SetStateAction<boolean>>;
  validatePhonelProfile: boolean;
  setValidatePhoneProfile: React.Dispatch<React.SetStateAction<boolean>>;
  validateNamelProfile: boolean;
  setValidateNameProfile: React.Dispatch<React.SetStateAction<boolean>>;

  realName: string;
  setRealName: React.Dispatch<React.SetStateAction<string>>;
  emailUrl: string;
  setEmailUrl: React.Dispatch<React.SetStateAction<string>>;
  mobileNumber: string;
  setMobileNumber: React.Dispatch<React.SetStateAction<string>>;
  promoSlug?: string;
  setPromoSlug: React.Dispatch<React.SetStateAction<string>>;
  isValidateAll: boolean;
  setIsValidateAll: React.Dispatch<React.SetStateAction<boolean>>;
}

const NameCardFromValidateContext = createContext<
  INameCardFromValidate | undefined
>(undefined);

const NameCardFromValidateProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [hasValidateButton, setHasValidateButton] = useState<boolean[]>([]);
  const [hasValidateImageUrl, setHasValidateImageUrl] = useState<boolean[]>([]);
  const [validateEmailProfile, setValidateEmailProfile] =
    useState<boolean>(false);
  const [validatePhonelProfile, setValidatePhoneProfile] =
    useState<boolean>(false);
  const [validateNamelProfile, setValidateNameProfile] =
    useState<boolean>(false);

  const [realName, setRealName] = useState<string>("");
  const [emailUrl, setEmailUrl] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [promoSlug, setPromoSlug] = useState<string>("");
  const [isValidateAll, setIsValidateAll] = useState<boolean>(true); // 24.11.07 SCH ★

  return (
    <NameCardFromValidateContext.Provider
      value={{
        hasValidateButton: hasValidateButton,
        setHasValidateButton: setHasValidateButton,
        hasValidateImageUrl: hasValidateImageUrl,
        setHasValidateImageUrl: setHasValidateImageUrl,
        validateEmailProfile: validateEmailProfile,
        setValidateEmailProfile: setValidateEmailProfile,
        validatePhonelProfile: validatePhonelProfile,
        setValidatePhoneProfile: setValidatePhoneProfile,
        validateNamelProfile: validateNamelProfile,
        setValidateNameProfile: setValidateNameProfile,

        realName: realName,
        setRealName: setRealName,
        emailUrl: emailUrl,
        setEmailUrl: setEmailUrl,
        mobileNumber: mobileNumber,
        setMobileNumber: setMobileNumber,
        promoSlug: promoSlug,
        setPromoSlug: setPromoSlug,
        isValidateAll: isValidateAll,
        setIsValidateAll: setIsValidateAll,
      }}
    >
      {children}
    </NameCardFromValidateContext.Provider>
  );
};
export default NameCardFromValidateProvider;

export const useNameCardFromValidate = () => {
  const nameCardFromValidateData = useContext(NameCardFromValidateContext);
  if (nameCardFromValidateData === undefined) {
    throw new Error(
      "nameCardFromValidateData must be used within a NameCardFromValidateProvider"
    );
  }
  return nameCardFromValidateData;
};
