import { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";

import classes from "./NameCardsList.module.sass";
import { useShareFlexMsg } from "../../../hooks/useShareFlexMsg";
import {
  queryNameCardsByLineId,
  queryNameCardsByMobileNumber,
  nameCardType,
  deleteNameCard,
} from "../../../api/index";
import shareLogo from "../../../image/back-icon.svg";
import editLogo from "../../../image/edit-icon.svg";
import deleteLogo from "../../../image/icon-delete.svg";
import defaultCoverImg from "../../../image/default-cover.svg";

import Loading from "../../Loading";

import { useAppStateStore } from "../../../store/appState";

import { format } from "date-fns"; // 24.10.31 SCH ★

const NameCardsList = () => {
  const navigate = useNavigate();
  const { shareNameCard, error } = useShareFlexMsg();
  const [nameCards, setNameCards] = useState<nameCardType[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { APP, BRAND, USER, PAGEMODE, NAMECARD_DATA } = useAppStateStore(); // 24.11.01 SCH ★
  const { appState, setAppState, currentConfig } = APP;
  const { brandState } = BRAND; // 24.11.01 SCH ★
  const { userState } = USER;
  const { accessToken } = userState;
  const { setCurrentPageModeState } = PAGEMODE;
  const {
    initNameCardV1Data,
    initNameCardV1Setting,
    initNameCardV3Data,
    initNameCardV3Setting,
  } = NAMECARD_DATA;
  const [errorMsg, setErrorMsg] = useState<{
    errMsg: string;
    error: boolean;
  }>({ errMsg: "", error: false });
  const [isDelteModalOpen, setIsDelteModalOpen] = useState<{
    show: boolean;
    cardId: number | null;
  }>({
    show: false,
    cardId: null,
  });

  const { role, eagle, enable, editable, trial_due_at } = userState; // 24.10.31 SCH
  const [trialDueAt, setTrialDueAt] = useState(""); // 24.10.31 SCH ★
  const [banLoign, setBanLoign] = useState(false); // 24.10.31 SCH ★
  const [isEditBanModalOpen, setIsEditBanModalOpen] = useState(false); // 24.11.02 SCH ★

  //有line_id就用 line_id 要名片資料, 沒有 line_id 就使用 mobile_number 來要名片列表資料
  const getUerNameCards = async () => {
    setAppState({ isLoading: true });
    try {
      if (accessToken && userState) {
        const { line_id, mobile_number } = userState;
        if (mobile_number) {
          const { data: res } = await queryNameCardsByMobileNumber(
            accessToken,
            mobile_number
          );
          if (res) {
            const { data } = res;
            console.log("name_cards", data);
            setNameCards(data);
          }
        } else if (line_id) {
          const { data: res } = await queryNameCardsByLineId(
            accessToken,
            mobile_number
          );
          if (res) {
            const { data } = res;
            console.log("name_cards", data);
            setNameCards(data);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMsg({ errMsg: JSON.stringify(error), error: true });
      setAppState({ isLoading: false });
    }
  };

  const DeleteNameCard = async (_card_id: number) => {
    try {
      if (accessToken) {
        setAppState({ isLoading: true });

        console.log("name_cards", `刪除${_card_id}`);
        await deleteNameCard(accessToken, _card_id);
      }
    } catch (error) {
      setErrorMsg({ errMsg: JSON.stringify(error), error: true });
      console.log(error);
    }
  };

  useEffect(() => {
    if (accessToken && userState) {
      let trialDueAt = trial_due_at?.toString();
      if (trialDueAt) setTrialDueAt(trialDueAt); // 24.10.31 SCH ★
      const now_date = format(new Date(), "yyyy-MM-dd");
      if (role < 2 && trialDueAt !== undefined) {
        if (brandState === undefined) {
          // 沒抓到資料！
          console.log("brandState", brandState);
          if (now_date > trialDueAt && !eagle) {
            setBanLoign(true); // 24.10.31 SCH ★
          }
        } else {
          // 有抓到資料！ // 24.11.01 SCH ★
          console.log("brandState", brandState);
          if (brandState.use_trial_due_at && now_date > trialDueAt && !eagle) {
            if (!brandState.use_login_log_count) {
              setBanLoign(true); // 24.11.01 SCH ★
            } else {
              if (brandState.login_log_count > brandState.trial_login_times) {
                setBanLoign(true); // 24.11.01 SCH ★
              }
            }
          }
        }
      }
    }

    if (accessToken && userState) {
      setTimeout(() => {
        getUerNameCards();
        setAppState({ isLoading: false });
      }, 500);
    }
  }, [
    accessToken,
    userState,
    appState.isLoading,
    brandState,
    eagle,
    role,
    trial_due_at,
  ]); // 24.11.01 SCH ★

  useEffect(() => {
    setCurrentPageModeState("nameCardsList");
    initNameCardV1Data();
    initNameCardV1Setting();
    initNameCardV3Data();
    initNameCardV3Setting();
  }, []);

  const getCoverImg = (_image_urls: string[]) => {
    if (!_image_urls || _image_urls?.length === 0) return defaultCoverImg;
    return `${currentConfig.REACT_APP_IMAGEBASEURL}${_image_urls[0]}`;
  };

  const coverImgErrorHandler = (
    _event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    _event.currentTarget.src = defaultCoverImg;
  };

  const timeFormat = (_originTime: string) => {
    const temp = _originTime.split("T");
    const date = temp[0];
    const time = () => {
      const timeArr = temp[1].split("+")[0].split(":");
      return timeArr[0] + ":" + timeArr[1];
    };
    const result = date + " " + time();
    return result;
  };

  useEffect(() => {
    error && setIsModalOpen(true);
  }, [error]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const okDelteModalHandle = () => {
    console.log(isDelteModalOpen.cardId);
    if (isDelteModalOpen.cardId) {
      DeleteNameCard(isDelteModalOpen.cardId);
    }
    setIsDelteModalOpen({
      show: false,
      cardId: null,
    });
  };

  const cancelDelteModalHandle = () => {
    setIsDelteModalOpen({
      show: false,
      cardId: null,
    });
  };

  const EditBanHandleOk = () => {
    setIsEditBanModalOpen(false);
  };

  const EditBanHandleCancel = () => {
    setIsEditBanModalOpen(false);
  };

  if (errorMsg.error === true) return <div>{errorMsg.errMsg}</div>;

  return (
    <>
      {appState.isLoading ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          {!appState.isLoading &&
          nameCards !== undefined &&
          nameCards.length === 0 ? (
            <h3>目前尚未新增電子名片</h3>
          ) : banLoign ? (
            <h3>您已逾免費試用期限（{`${trialDueAt}`}）喔！</h3> // 24.10.31 SCH ★
          ) : (
            nameCards &&
            nameCards.map((item) => (
              <div className={classes.cardContainer} key={item.card_id}>
                <div className={classes.coverImg}>
                  <img
                    onError={(e) => coverImgErrorHandler(e)}
                    src={getCoverImg(item.image_urls)}
                    alt="CoverImg"
                  />
                  <div className={classes.buttonBar}>
                    <div
                      className={classes.buttonDefault}
                      onClick={() => shareNameCard(item.card_id.toString())}
                    >
                      <img src={shareLogo} alt="share" />
                    </div>

                    <div
                      className={classes.buttonDefault}
                      onClick={() => {
                        if (role >= 2 || editable || eagle) {
                          // 24.11.02 SCH
                          navigate(`/update/${item.card_id}`);
                        } else {
                          setIsEditBanModalOpen(true); // 24.11.02 SCH
                        }
                      }}
                    >
                      <img src={editLogo} alt="edit" />
                    </div>

                    <div
                      className={classes.buttonDelete}
                      onClick={() =>
                        setIsDelteModalOpen({
                          show: true,
                          cardId: item.card_id,
                        })
                      }
                    >
                      <img src={deleteLogo} alt="delete" />
                    </div>
                  </div>
                </div>
                <div className={classes.cardTitle}>{`${item.card_title}`}</div>
                <div className={classes.changelog}>
                  <div className={classes.changelogCol}>
                    <div>上次編輯</div>
                    <div className={classes.time}>
                      {timeFormat(item.updated_at)}
                    </div>
                  </div>
                  <div className={classes.changelogCol}>
                    <div>建立時間</div>
                    <div className={classes.time}>
                      {timeFormat(item.created_at)}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}

      <Modal
        title="提醒"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>名片無法發送，請檢查名片內容有無不符合規定地方</p>
      </Modal>

      <Modal
        title="提醒"
        open={isDelteModalOpen.show}
        onCancel={cancelDelteModalHandle}
        footer={false}
      >
        <div className={classes.deleteModal}>
          <p>是否確定要刪除此電子名片</p>
          <div>
            <Button
              className={classes.button}
              type="primary"
              ghost
              onClick={okDelteModalHandle}
            >
              <div className={classes.buttonWord}>確定刪除</div>
            </Button>
            <Button
              className={classes.button}
              type="primary"
              ghost
              onClick={cancelDelteModalHandle}
            >
              <div>取消</div>
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        title="編輯權益限制提醒"
        open={isEditBanModalOpen}
        onOk={EditBanHandleOk}
        onCancel={EditBanHandleCancel}
      >
        <p>
          無法編輯名片，您現持有張數已滿免費試用數（
          {`${brandState.trial_name_cards}`}）喔！
        </p>
      </Modal>
    </>
  );
};
export default NameCardsList;
