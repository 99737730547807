import { useEffect, useState } from "react";
import { Input, Form, Button, message } from "antd";

import { memberCheck, UserCheckResponse } from "../../api";
import { useAppStateStore } from "../../store/appState";
import classes from "./MemberCheck.module.sass";
import liff from "@line/liff";

const MemberCheck = () => {
  const { USER, PAGEMODE, MEMBERCHECK } = useAppStateStore();
  const { userState } = USER;
  const { accessToken } = userState;
  const { isMemberCheck, setIsMemberCheck } = MEMBERCHECK;
  const { setCurrentPageModeState, currentPageModeState } = PAGEMODE;
  const [res, setRes] = useState<UserCheckResponse | undefined>(undefined);
  const [info, setInfo] = useState<
    { mobile_number: string; realname: string } | undefined
  >(undefined);
  const memberCheckApi = async (
    _accessToken: string,
    _mobileNumber: string,
    _realname: string
  ) => {
    try {
      const { data } = await memberCheck(
        _accessToken,
        _mobileNumber,
        _realname
      );
      setRes(data);
      setIsMemberCheck(true);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const infoHandler = (
    infotype: "mobile_number" | "realname",
    value: string
  ) => {
    if (infotype === "mobile_number") {
      setInfo((prevInfo) =>
        Object.assign({}, prevInfo, { mobile_number: value })
      );
    } else if (infotype === "realname") {
      setInfo((prevInfo) => Object.assign({}, prevInfo, { realname: value }));
    }
  };

  useEffect(() => {
    setCurrentPageModeState("userCheck");
  }, []);

  return (
    <div className={classes.container}>
      <Form layout="vertical">
        <Form.Item label={<span>會員姓名</span>} hasFeedback>
          <Input
            type="text"
            name="realname"
            placeholder="會員姓名"
            defaultValue={userState.realname ?? ""}
            onChange={(e) => {
              infoHandler("realname", e.target.value);
            }}
          ></Input>
        </Form.Item>
        <Form.Item label={<span>手機號碼</span>} hasFeedback>
          <Input
            name="mobile_number"
            placeholder="聯絡電話"
            defaultValue={userState.mobile_number ?? ""}
            onChange={(e) => {
              infoHandler("mobile_number", e.target.value);
            }}
          ></Input>
        </Form.Item>
        <Button
          onClick={() => {
            if (
              !info ||
              !info.mobile_number ||
              info.mobile_number.trim().length !== 10 ||
              info.mobile_number[0] !== "0"
            ) {
              console.log(info && info.mobile_number.trim().length !== 10);
              return message.error("請檢查手機號碼內容");
            }

            if (!info || !info.realname || info.realname.trim().length < 1) {
              return message.error("請檢查姓名輸入內容，不得留空");
            }
            accessToken &&
              info &&
              memberCheckApi(accessToken, info.mobile_number, info.realname);
          }}
        >
          確認送出
        </Button>
        {res &&
          (res.data && res.data.success ? (
            <div className={classes.res}>
              <div>{`ID : ${res.data.user_check.id}`}</div>
              <div>{`Line ID : ${res.data.user_check.line_id}`}</div>
              <div>{`Mobile Number : ${res.data.user_check.mobile_number}`}</div>
              <div>{`Realname : ${res.data.user_check.realname}`}</div>
            </div>
          ) : (
            <div className={classes.res}>
              <div>手機號碼已經存在了</div>
              <div>{res?.data.message}</div>
            </div>
          ))}
      </Form>
    </div>
  );
};
export default MemberCheck;
