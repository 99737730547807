/**
 * 檢查所有按鈕是否通過驗證
 */
export const isAllValidationsPassed = (buttonValidate: boolean[]) => {
  return buttonValidate.every((result) => result === true);
};

/**
 * 檢查網址是否是有效網址
 */
export const isValidHttpUrl = (_url: string) => {
  try {
    const url = new URL(_url);
    if (url.protocol === "http:" || url.protocol === "https:") {
      let domain = _url.split("/")[2];
      domain = domain.split(":")[0];
      var reg =
        /^(.+\.)(com|edu|gov|int|mil|net|org|biz|info|name|museum|coop|aero|[a-z][a-z])/;
      if (reg.test(domain)) {
        return true;
      } else {
        return false;
      }
    }
  } catch (err) {
    return false;
  }
};

/**
 * 檢查電話是否是有效電話
 */
export const validatePhoneNumber = (value: string) => {
  if (value && value.length === 10 && value[0] === "0" && value[1] === "9") {
    return true;
  } else {
    return false;
  }
};
