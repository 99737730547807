import { CFlexBubble } from "../../flexMessageType";

export const hasImageInThisNameCard = (_nameCardContent: CFlexBubble[]) => {
  return _nameCardContent.some((cflexBubble, cflexBubbleIndex) => {
    return cflexBubble.body?.contents.some(
      (flexComponent, flexComponentIndex) => {
        return flexComponent.type === "image";
      }
    );
  });
};
