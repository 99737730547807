import { useEffect, useState, useRef } from "react";
import classes from "./UserBonusList.module.sass";
import { queryUserBonus, userBonusType } from "../../../api/index"; // 24.11.14 SCH ★

import { useAppStateStore } from "../../../store/appState";

import { format } from "date-fns"; // 24.11.04 SCH ★

const UserBonusList = () => {
  const [userBonusList, setUserBonusList] = useState<userBonusType[]>(); // 24.11.14 SCH ★
  const original_commission = useRef<number>(0); // 24.11.12 SCH ★
  const commission_discount = useRef<number>(0); // 24.11.12 SCH ★
  const amount_commission = useRef<number>(0); // 24.11.12 SCH ★
  const dates_range2 = useRef<string>(""); // 24.11.12 SCH ★
  const { USER, USER_BONUS, PAGEMODE } = useAppStateStore(); // 24.11.06 SCH ★
  const { userState } = USER;
  const { accessToken } = userState;
  const { userBonusState, setUserBonusState } = USER_BONUS; // 24.11.05 SCH ★
  const { setCurrentPageModeState } = PAGEMODE; // 24.11.06 SCH ★

  const [errorMsg, setErrorMsg] = useState<{
    errMsg: string;
    error: boolean;
  }>({ errMsg: "", error: false });

  useEffect(() => {
    setCurrentPageModeState("userBonus"); // 24.11.06 SCH ★
  }, []);

  // 24.11.02 SCH ★
  const getUerBonusList = async () => {
    try {
      if (accessToken) {
        let y4m2 = userBonusState.y4m2; // 24.11.05 SCH ★
        console.log("userBonusState.y4m2 =", y4m2);
        const { data: res } = await queryUserBonus(accessToken, y4m2);
        if (res) {
          original_commission.current = res.original_commission;
          commission_discount.current = res.commission_discount;
          amount_commission.current = res.amount_commission;
          dates_range2.current = res.dates_range2; // 24.11.12 SCH ★

          const { data } = res;
          console.log("queryUserBonus", data); // 24.11.04 SCH ★
          setUserBonusList(data);
        }
        setUserBonusState({ isUserBonus: true }); // 24.11.05 SCH ★
      }
    } catch (error) {
      console.log(error);
      setErrorMsg({ errMsg: JSON.stringify(error), error: true });
    }
  };

  useEffect(() => {
    if (accessToken && userState) {
      getUerBonusList();
      console.log(userBonusList);
    }
  }, [accessToken, userState]); // 24.11.02 SCH ★

  const timeFormat = (_originTime: string) => {
    const temp = _originTime.split("T");
    const date = temp[0];
    const time = () => {
      const timeArr = temp[1].split("+")[0].split(":");
      return timeArr[0] + ":" + timeArr[1];
    };
    const result = date + " " + time();
    return result;
  };

  if (errorMsg.error === true) return <div>{errorMsg.errMsg}</div>;

  return (
    <>
      <div className={classes.container}>
        {userBonusList !== undefined && userBonusList.length > 0 ? (
          <div className={classes.listContainer}>
            <div className={classes.columnTitle}>
              佣金原價合計：{`${original_commission.current}`}
            </div>
            <div className={classes.columnTitle}>
              佣金折價合計：{`${commission_discount.current}`}
            </div>
            <div className={classes.columnTitle}>
              推廣佣金合計：{`${amount_commission.current}`}
            </div>
            <div className={classes.columnTitle}>
              訂單統計期間：{`${dates_range2.current}`}
            </div>
          </div>
        ) : (
          <p></p> // 24.11.12 SCH ★★★
        )}

        {userBonusList !== undefined &&
        userBonusList.length === 0 &&
        (userBonusState.y4m2 === undefined || userBonusState.y4m2 === "") ? (
          <h3>您尚未有聯盟行銷推廣佣金資料</h3> // 24.11.02 SCH ★
        ) : userBonusState.y4m2 !== undefined &&
          userBonusState.y4m2 > "" &&
          userBonusList !== undefined &&
          userBonusList.length === 0 ? (
          <h3>您於（{`${userBonusState.y4m2}`}）無推廣佣金資料喔！</h3> // 24.11.11 SCH ★
        ) : (
          userBonusList &&
          userBonusList.map((item) => (
            <div className={classes.listContainer} key={item.order_id}>
              <div className={classes.listTitle}>
                方案名稱：{`${item.plan_name}`}
              </div>
              <div className={classes.columnTitle}>
                會員手機：{`${item.mobile_number}`}
              </div>
              <div className={classes.columnTitle}>
                會員姓名：{`${item.name}`}
              </div>
              <div className={classes.columnTitle}>
                會員電郵：{`${item.email}`}
              </div>
              <div className={classes.columnTitle}>
                訂單編號：{`${item.uid}`}
              </div>
              <div className={classes.columnTitle}>
                訂單狀態：{`${item.status}`}
              </div>
              <div className={classes.columnTitle}>
                訂單金額：{`${item.amount}`}
              </div>
              <div className={classes.columnTitle}>
                推廣佣金：{`${item.commission}`}
              </div>
              <div className={classes.changelog}>
                <div className={classes.changelogCol}>
                  <div>建立時間：</div>
                  <div className={classes.time}>
                    {timeFormat(item.created_at)}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};
export default UserBonusList;
