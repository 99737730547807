import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "./UserBonusListFooter.module.sass"; // 24.11.06 SCH ★
import { useAppStateStore } from "../../../store/appState";

const UserBonusListFooter = () => {
  const navigate = useNavigate();
  const { USER_BONUS } = useAppStateStore();
  const { userBonusState } = USER_BONUS; // 24.11.06 SCH ★
  const { isUserBonus } = userBonusState;

  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      {isUserBonus && (
        <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
          <Button
            type="primary"
            className={`${classes.button} ${classes.buttonGreyBg}`}
            onClick={() => {
              navigate(`/`);
            }}
          >
            <div className={classes.buttonWord}>關閉</div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default UserBonusListFooter;
