import { Response, axios } from "./index";

//--------------interface and type---------------
export interface meDataType {
  data: {
    id: number;
    line_id: string;
    brand_id: number;
    email: string;
    created_at: string;
    birthday: string | null;
    address: string | null;
    gender: string | null;
    photo_url: string | null;
    is_normal: boolean;
    // 一般用戶 1 正式用戶 0
    is_common: 0 | 1;
    realname: string;
    nickname: string;
    mobile_number: string;
    //  已經是會員 1 不是會員 0
    isMember: 0 | 1;
    // 已經有紀錄 1 尚未紀錄 0
    logging: 0 | 1;
    // 已經登入為 1 尚未登入為 0
    isLoggedIn: 0 | 1;
    promo_hash: string;
    promo_slug: string | null;
    // 1：免費會員 2:付費會員
    role: 1 | 2;
    promoter_id: string | null;
    promoter_mobile: string | null;
    promoter_uid: string | null;
  };
}

export interface updateMeResDataType {
  data: {
    data: {
      id: number;
      line_id: string;
      brand_id: number;
      email: string;
      created_at: string;
      birthday: string | null;
      address: string | null;
      gender: string | null;
      photo_url: string | null;
      is_normal: boolean;
      // 一般用戶 1 正式用戶 0
      is_common: 0 | 1;
      realname: string;
      nickname: string;
      mobile_number: string;
      //  已經是會員 1 不是會員 0
      isMember: 0 | 1;
      // 已經有紀錄 1 尚未紀錄 0
      logging: 0 | 1;
      // 已經登入為 1 尚未登入為 0
      isLoggedIn: 0 | 1;
      promo_hash: string;
      promo_slug: string | null;
      // 1：免費會員 2:付費會員
      role: 1 | 2;
      promoter_id: string | null;
      promoter_mobile: string | null;
      promoter_uid: string | null;
    };
  };
}

export interface IProfile {
  realname: string | null;
  email: string;
  mobile_number: string | null;
  promo_slug: string | null;
}

//-----------------------------

/**
 * 會員自身資料api
 * @param accessToken user access Token
 */
export const getMe = async (
  accessToken: string
): Promise<Response<meDataType>> => {
  try {
    const { data } = await axios.request<meDataType>({
      url: "/api/member/me",
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    return { error, data: undefined };
  }
};

/**
 * 修改會員自身資料api
 * @param accessToken user access Token
 */
export const updateMe = async (
  accessToken: string,
  profileContent: IProfile
): Promise<Response<updateMeResDataType>> => {
  try {
    const { data } = await axios.request<updateMeResDataType>({
      url: "/api/member/me",
      method: "PATCH",
      data: {
        ...profileContent,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    return { error, data: undefined };
  }
};
