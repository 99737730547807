import { Form, Input, Row, Col, Button } from "antd";
import { IurlButton } from "../../../../interface/flexMessage";

import classes from "./Button.module.sass";
import { IButtonProps } from "./Type";
import { useEffect, useState } from "react";
import { useNameCardFromValidate } from "../../../../context/NameCardFromValidate";

import {
  nameCardV1DataType,
  useAppStateStore,
  buttonType,
} from "../../../../store/appState";

const UrlButton = ({
  id,
  elementIndex,
  thisPageFlexMessageIndex,
  buttonType,
}: IButtonProps) => {
  const { NAMECARD_DATA } = useAppStateStore();
  const { nameCardData, setNameCardV1Data } = NAMECARD_DATA;
  const { nameCardV1Data } = nameCardData;

  const [isValidateUrl, setIsValidateUrl] = useState<boolean>(false);
  const { hasValidateButton, setHasValidateButton } = useNameCardFromValidate();

  const transformContnetToContent = (value: buttonType) => {
    if ("content" in value) return value.content;
    if ("contnet" in value) {
      const { _key, id, buttonText, buttonUrl } = (
        value as {
          contnet: {
            _key: string;
            buttonText: string;
            buttonUrl: string;
            id: number;
          };
        }
      ).contnet;
      const content = {
        _key: _key,
        buttonText: buttonText,
        buttonUrl: buttonUrl,
        id: id,
      };
      return content;
    }
  };

  const handleUrlChange = (_url: string) => {
    if (nameCardV1Data) {
      const _flexMessage: nameCardV1DataType[] = Array.from(nameCardV1Data);
      const _content = _flexMessage[thisPageFlexMessageIndex].button[
        elementIndex
      ].content as IurlButton;
      _content.buttonUrl = _url;
      _flexMessage[thisPageFlexMessageIndex].button[elementIndex].content =
        _content;

      setNameCardV1Data(_flexMessage);
    }
  };

  const handleTextChange = (_text: string) => {
    if (nameCardV1Data) {
      const _flexMessage: nameCardV1DataType[] = Array.from(nameCardV1Data);
      const _content = _flexMessage[thisPageFlexMessageIndex].button[
        elementIndex
      ].content as IurlButton;
      _content.buttonText = _text;
      _flexMessage[thisPageFlexMessageIndex].button[elementIndex].content =
        _content;

      setNameCardV1Data(_flexMessage);
    }
  };

  const remove = (_index: number) => {
    if (nameCardV1Data) {
      const _flexMessage: nameCardV1DataType[] = Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndex].button.splice(_index, 1);
      setNameCardV1Data(_flexMessage);
      removeCurrentValidateUrl(elementIndex);
    }
  };

  const addCurrentValidateUrl = (_validate: boolean, elementIndex: number) => {
    const _temp = Array.from(hasValidateButton);
    if (_temp[elementIndex] !== undefined) {
      _temp[elementIndex] = _validate;
    } else {
      _temp.push(_validate);
    }
    setHasValidateButton(_temp);
  };

  const removeCurrentValidateUrl = (elementIndex: number) => {
    const _temp = Array.from(hasValidateButton);
    if (_temp[elementIndex] !== undefined) {
      _temp.splice(elementIndex, 1);
      setHasValidateButton(_temp);
    }
  };

  const isValidHttpUrl = (_url: string) => {
    try {
      const url = new URL(_url);
      if (url.protocol === "http:" || url.protocol === "https:") {
        let domain = _url.split("/")[2];
        domain = domain.split(":")[0];
        var reg =
          /^(.+\.)(com|edu|gov|int|mil|net|org|biz|info|name|museum|coop|aero|[a-z][a-z])/;
        if (reg.test(domain)) {
          setIsValidateUrl(true);
          addCurrentValidateUrl(true, elementIndex);
        } else {
          setIsValidateUrl(false);
          addCurrentValidateUrl(false, elementIndex);
        }
      }
    } catch (err) {
      setIsValidateUrl(false);
      addCurrentValidateUrl(false, elementIndex);
    }
  };

  useEffect(() => {
    if (!nameCardV1Data) return;
    isValidHttpUrl(
      {
        ...(nameCardV1Data[thisPageFlexMessageIndex].button[elementIndex]
          .content as IurlButton),
      }.buttonUrl
    );
  }, [
    nameCardV1Data &&
      {
        ...(nameCardV1Data[thisPageFlexMessageIndex].button[elementIndex]
          .content as IurlButton),
      }.buttonUrl,
  ]);

  return (
    <Row justify="center" align="middle" gutter={[8, 8]}>
      <Col span={24}>
        <div className={classes.content}>
          <div className={classes.col}>
            <Form.Item
              label={
                <span className={classes.label}>
                  按鈕 {elementIndex + 1} 文字
                </span>
              }
            >
              <Input
                placeholder={`請輸入按鈕 ${elementIndex + 1} 文字`}
                disabled={buttonType === "shareNameCardButton"}
                id={`${elementIndex}`}
                onChange={(e) =>
                  //找出該頁面的flexMessage index
                  //拿該頁面button陣列 使用map 遍歷各個item
                  // 利用button id === e.target.id找出現在onChange的item
                  handleTextChange(e.target.value)
                }
                defaultValue={
                  nameCardV1Data
                    ? transformContnetToContent(
                        nameCardV1Data[thisPageFlexMessageIndex].button[
                          elementIndex
                        ]
                      )?.buttonText
                    : ""
                }
                value={
                  nameCardV1Data === null
                    ? undefined
                    : {
                        ...(nameCardV1Data[thisPageFlexMessageIndex].button[
                          elementIndex
                        ].content as IurlButton),
                      }.buttonText
                }
              />
            </Form.Item>
          </div>
          <div className={classes.col}>
            <Form.Item
              validateStatus={isValidateUrl ? "success" : "error"}
              hasFeedback
              label={
                <span className={classes.label}>
                  按鈕 {elementIndex + 1} 連結
                </span>
              }
            >
              <Input
                placeholder={`請輸入按鈕 ${elementIndex} 連結`}
                disabled={buttonType === "shareNameCardButton"}
                className={classes.input}
                id={`${elementIndex}`}
                onChange={(e) => {
                  handleUrlChange(e.target.value);
                  isValidHttpUrl(e.target.value);
                }}
                value={
                  nameCardV1Data === null
                    ? undefined
                    : {
                        ...(nameCardV1Data[thisPageFlexMessageIndex].button[
                          elementIndex
                        ].content as IurlButton),
                      }.buttonUrl
                }
              />
            </Form.Item>
          </div>
          <div>
            <Button
              size="small"
              className={classes.button}
              onClick={() => remove(elementIndex)}
            >
              X
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default UrlButton;
