import { create } from "zustand";
import { getCurrentConfig } from "../utils/config";
import { devtools } from "zustand/middleware";
import { CFlexBubble } from "../../flexMessageType";

type currntConfigType = {
  REACT_APP_API_URL: string;
  REACT_APP_ENV: string;
  REACT_APP_VIP_URL: string;
  REACT_APP_LIFFID: string;
  REACT_APP_IMAGEPATH: string;
  REACT_APP_IMAGEBASEURL: string;
};

type LiffStateType = {
  lineIsLoggedIn: boolean;
  isInClient: boolean;
  lineIDToken: string | null;
  lineAccessToken: string | null;
  ready: boolean;
};
type ipFlowStateType = {
  isLogin: boolean;
  isEagle: boolean;
  role: 1 | 2;
};

type appStateType = {
  isLoading: boolean;
  isError: boolean;
  errorMsg: string | null;
  errorCode: number | null;
};

type appType = {
  currentConfig: currntConfigType;
  liffState: LiffStateType;
  setLiffState: (newLiffState: Partial<LiffStateType>) => void;
  ipFlowState: ipFlowStateType;
  setIpFlowState: (newIpFlowState: Partial<ipFlowStateType>) => void;
  appState: appStateType;
  setAppState: (newAppState: Partial<appStateType>) => void;
};

// 24.11.01 SCH ★
type brandStateType = {
  accessToken: string | null;
  trial_name_cards: 0;
  trial_months: 0;
  trial_login_times: 0;
  use_name_card_count: 0;
  use_trial_due_at: 0;
  use_login_log_count: 0;
  login_log_count: 0;
};

type userStateType = {
  user_id: number | null;
  accessToken: string | null;
  expires_in: string;
  is_normal: boolean;
  mobile_number: string;
  // 一般用戶 1 正式用戶 0
  is_common: 0 | 1;
  // 已經登入為 1 尚未登入為 0
  isLoggedIn: 0 | 1;
  // 已經是會員 1 不是會員 0
  isMember: 0 | 1;
  // 已經有紀錄 1 尚未紀錄 0
  logging: 0 | 1;
  // 是老鷹推廣夥伴 1 | 不是老鷹推廣夥伴 0
  eagle: 0 | 1;
  line_id: string;
  nickname: string;
  realname: string;
  brand_id: number;
  regkey: string | null;
  regkey_expire_at: string | null;
  // 非付費會員  1 |  付費會員 2
  role: 1 | 2;
  type: string;
  // 性別：0 : 女、1 : 男 、2 : 其他 // 24.10.31 SCH
  gender: string | null;
  address: string | null;
  birthday: string | null;
  email: string;
  photo_url: string | null;
  enable: 0; // 24.10.31 SCH (1=可新增)
  editable: 0; // 24.10.31 SCH (1=可修改)
  expired_at: string | null;
  login_at: string | null;
  logout_at: string | null;
  created_at: string;
  updated_at: string;
  pay_amount: 0;
  disconnect_line: 0;
  uid: string | null;
  line_pay: 0;
  promoter_uid: string | null; // 24.11.04 SCH
  promoter_mobile: string | null;
  promo_hash: string | null;
  promo_slug: string | null;
  promoter_id2: string | null;
  promoter_mobile2: string | null;
  bank_code: string | null; // 24.10.31 SCH
  bank_account: string | null; // 24.10.31 SCH
  name_card_count: 0; // 24.10.31 SCH
  trial_due_at: string | null; // 24.10.31 SCH
};

type profileInuptType = {
  realname: string | null;
  email: string;
  mobile_number: string | null;
  promo_slug: string | null;
  bank_code: string | null;
  bank_account: string | null;
  isValidatePhoneNumber: boolean;
  isValidateEmail: boolean;
  isValidateName: boolean;
  isValidatePromoSlug: boolean;
  isValidateBankCode: boolean;
  isValidateBankAccount: boolean;
  isValidateAll: boolean; // 24.11.06 SCH
};

// 24.11.01 SCH ★
type brandType = {
  brandState: brandStateType;
  setBrandState: (newBrandState: Partial<brandStateType>) => void;
};

type userType = {
  userState: userStateType;
  setUserState: (newUserState: Partial<userStateType>) => void;
};

type profilePageType = {
  profileInputData: profileInuptType;
  setProfileInputData: (profileInputData: Partial<profileInuptType>) => void;
  initProfileInputData: () => void;
};

type pageModeType = {
  currentPageModeState:
    | "nameCardsList"
    | "profile"
    | "createNameCard"
    | "updateNameCard"
    | "createNameCardFullImgMode"
    | "updateNameCardFullImgMode"
    | "userCheck"
    | "userBonus" // 24.11.02 SCH ★
    | "null";
  setCurrentPageModeState: (
    newCurrentPageModeState: Partial<
      | "nameCardsList"
      | "profile"
      | "createNameCard"
      | "updateNameCard"
      | "createNameCardFullImgMode"
      | "updateNameCardFullImgMode"
      | "userCheck"
      | "userBonus" // 24.11.02 SCH ★
      | "null"
    >
  ) => void;
};

type nameCardOperationStateType = {
  action:
    | "create"
    | "update"
    | "reCreate"
    | "goProfile"
    | "goUserBonus"
    | "goNameCardList"
    | "null";
  currentNameCardType: "v1.0" | "v2.0" | "v3.0" | "null";
  selectNameCardType: "v1.0" | "v2.0" | "v3.0" | "null";
};

type nameCardOperationType = {
  nameCardOperationState: nameCardOperationStateType;
  setNameCardOperationState: (
    newNameCardOperationState: Partial<nameCardOperationStateType>
  ) => void;
};

// 24.11.04 SCH ★
type userBonusStateType = {
  y4m2: string | undefined;
  date_start: string | undefined;
  date_end: string | undefined;
  isUserBonus: boolean; // 24.11.05 SCH ★
};

// 24.11.04 SCH ★
type userBonusType = {
  userBonusState: userBonusStateType;
  setUserBonusState: (newUserBonusState: Partial<userBonusStateType>) => void;
};

export type buttonType =
  | {
      type: "urlButton";
      content: {
        _key: string;
        buttonText: string;
        buttonUrl: string;
        id: number;
      };
      _key: string;
      id: number;
    }
  | {
      type: "phoneButton";
      content: {
        _key: string;
        buttonText: string;
        phoneNumber: string;
        id: number;
      };
      _key: string;
      id: number;
    }
  | {
      type: "shareNameCardButton";
      content: {
        _key: string;
        buttonText: string;
        buttonUrl: string;
        id: number;
      };
      _key: string;
      id: number;
    };

export type nameCardV1DataType = {
  id: string;
  IntroText: { _key: string; id: number; inputValue: string }[];
  imageUrl: {
    _key: string;
    imageUrl: string;
  }[];
  button: buttonType[];
  title: string;
  titleColor: string;
  introTextColor: string;
  cardBackgroundColor: string;
};

export type fontSizeType =
  | "xxs"
  | "sx"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "xxl"
  | "3xl"
  | "4xl"
  | "5xl";

export type nameCardV1SettingType = {
  altText: string;
  cardTitle: string;
  titleFontSize: fontSizeType;
  describeFontSize: fontSizeType;
};
export type nameCardV3SettingType = {
  cardTitle: string;
  altText: string;
};

export const initNameCardV1Data: nameCardV1DataType = {
  id: "newTab-1",
  IntroText: [{ _key: "newTab-1", id: 0, inputValue: " " }],
  imageUrl: [
    {
      _key: "newTab-1",
      imageUrl: "",
    },
  ],
  button: [
    {
      _key: "newTab-1",
      id: 0,
      content: {
        buttonText: " ",
        buttonUrl: "https://line.me/zh-hant/",
        id: 0,
        _key: "newTab-1",
      },
      type: "urlButton",
    },
  ],
  title: "姓名",
  titleColor: "#000000",
  introTextColor: "#000000",
  cardBackgroundColor: "#ffffff",
};

const initNameCardV1Setting: nameCardV1SettingType = {
  altText: "",
  cardTitle: "",
  titleFontSize: "xl",
  describeFontSize: "sm",
};

const initNameCardV3Data: CFlexBubble = {
  type: "bubble",
  body: {
    type: "box",
    layout: "vertical",
    contents: [],
    paddingAll: "0px",
  },
};

const initNameCardV3Setting: nameCardV3SettingType = {
  altText: "",
  cardTitle: "",
};

type nameCardDataType = {
  nameCardV1Data: nameCardV1DataType[];
  nameCardV1Setting: nameCardV1SettingType;
  nameCardV2Data: null;
  nameCardV3Data: CFlexBubble[];
  nameCardV3Setting: nameCardV3SettingType;
};

type memberCheckType = {
  isMemberCheck: boolean;
  setIsMemberCheck: (_isMemberCheck: boolean) => void;
};

type nameCardContentDataType = {
  nameCardData: nameCardDataType;
  setNameCardV1Data: (newNameCardV1Data: nameCardV1DataType[]) => void;
  setNameCardV1Setting: (
    newNameCardV1Setting: Partial<nameCardV1SettingType>
  ) => void;
  setNameCardV2Data: (newNameCardV2Data: null) => void;
  setNameCardV3Data: (newNameCardV3Data: CFlexBubble[]) => void;
  setNameCardV3Setting: (
    newNameCardV3Setting: Partial<nameCardV3SettingType>
  ) => void;
  initNameCardV1Data: () => void;
  initNameCardV1Setting: () => void;
  initNameCardV3Data: () => void;
  initNameCardV3Setting: () => void;
};

type bodyBlockRefType = {
  bodyBlockRef: HTMLDivElement | null;
  setbBdyBlockRef: (_bodyBlockRef: HTMLDivElement) => void;
};

type NavListHeaderType = {
  isOpen: boolean;
  toggle: () => void;
  close: () => void;
};

export type useAppStateStoreType = {
  APP: appType;
  BRAND: brandType; // 24.11.01 SCH ★
  USER: userType;
  PROFILE_PAGE: profilePageType;
  PAGEMODE: pageModeType;
  NAMECARD_OPERATION: nameCardOperationType;
  NAMECARD_DATA: nameCardContentDataType;
  MEMBERCHECK: memberCheckType;
  BODYBLOCKREF: bodyBlockRefType;
  USER_BONUS: userBonusType; // 24.11.04 SCH ★
  NavListHeader: NavListHeaderType; // 新增 nav 狀態
};

export const useAppStateStore = create<useAppStateStoreType>()(
  devtools(
    // persist(
    (set) => ({
      APP: {
        currentConfig: getCurrentConfig(),
        liffState: {
          lineAccessToken: null,
          lineIDToken: null,
          isInClient: false,
          lineIsLoggedIn: false,
          ready: false,
        },
        setLiffState: (newLiffState: Partial<LiffStateType>) =>
          set((state) => ({
            APP: {
              ...state.APP,
              liffState: {
                ...state.APP.liffState,
                ...newLiffState,
              },
            },
          })),
        ipFlowState: {
          isLogin: false,
          isEagle: false,
          role: 1,
        },
        setIpFlowState: (newIpFlowState) =>
          set((state) => ({
            ...state,
            ipFlowState: {
              ...state.APP.ipFlowState,
              ...newIpFlowState,
            },
          })),
        appState: {
          isLoading: false,
          isError: false,
          errorMsg: null,
          errorCode: null,
        },
        setAppState: (newAppState) =>
          set((state) => ({
            ...state,
            APP: {
              ...state.APP,
              appState: {
                ...state.APP.appState,
                ...newAppState,
              },
            },
          })),
      },
      BRAND: {
        brandState: {
          accessToken: null,
          trial_name_cards: 0,
          trial_months: 0,
          trial_login_times: 0,
          use_name_card_count: 0,
          use_trial_due_at: 0,
          use_login_log_count: 0,
          login_log_count: 0,
        },
        setBrandState: (newBrandState) =>
          set((state) => ({
            ...state,
            BRAND: {
              ...state.BRAND,
              brandState: {
                ...state.BRAND.brandState,
                ...newBrandState,
              },
            },
          })),
      },
      USER: {
        userState: {
          user_id: null,
          accessToken: null,
          expires_in: "",
          is_normal: true,
          mobile_number: "",
          // 一般用戶 1 正式用戶 0
          is_common: 0,
          // 已經登入為 1 尚未登入為 0
          isLoggedIn: 0,
          // 已經是會員 1 不是會員 0
          isMember: 0,
          // 已經有紀錄 1 尚未紀錄 0
          logging: 0,
          // 是老鷹推廣夥伴 1 | 不是老鷹推廣夥伴 0
          eagle: 0,
          line_id: "",
          nickname: "",
          realname: "",
          brand_id: 1,
          regkey: null,
          regkey_expire_at: null,
          // 非付費會員  1 |  付費會員 2
          role: 1,
          type: "",
          // 性別：0 : 女、1 : 男 、2 : 其他 // 24.10.31 SCH
          gender: null,
          address: "",
          birthday: null,
          email: "",
          photo_url: null,
          enable: 0, // 24.10.31 SCH (1=可新增)
          editable: 0, // 24.10.31 SCH (1=可修改)
          expired_at: null,
          login_at: null,
          logout_at: null,
          created_at: "",
          updated_at: "",
          pay_amount: 0,
          disconnect_line: 0,
          uid: null,
          line_pay: 0,
          promoter_uid: null, // 24.11.04 SCH
          promoter_mobile: null,
          promo_hash: null,
          promo_slug: null,
          promoter_id2: null,
          promoter_mobile2: null,
          bank_code: null, // 24.10.31 SCH
          bank_account: null, // 24.10.31 SCH
          name_card_count: 0, // 24.10.31 SCH
          trial_due_at: null, // 24.10.31 SCH
        },
        setUserState: (newUserState) =>
          set((state) => ({
            ...state,
            USER: {
              ...state.USER,
              userState: {
                ...state.USER.userState,
                ...newUserState,
              },
            },
          })),
      },
      PROFILE_PAGE: {
        profileInputData: {
          realname: "",
          email: "",
          mobile_number: "",
          promo_slug: null,
          bank_code: null,
          bank_account: null,
          isValidatePhoneNumber: false,
          isValidateEmail: false,
          isValidateName: false,
          isValidatePromoSlug: false,
          isValidateBankCode: false,
          isValidateBankAccount: false,
          isValidateAll: true, // 24.11.06 SCH
        },
        setProfileInputData: (newProfileInputData) =>
          set((state) => ({
            ...state,
            PROFILE_PAGE: {
              ...state.PROFILE_PAGE,
              profileInputData: {
                ...state.PROFILE_PAGE.profileInputData,
                ...newProfileInputData,
              },
            },
          })),
        initProfileInputData: () =>
          set((state) => ({
            ...state,
            PROFILE_PAGE: {
              ...state.PROFILE_PAGE,
              profileInputData: {
                ...state.PROFILE_PAGE.profileInputData,
                realname: "",
                email: "",
                mobile_number: "",
                promo_slug: "",
                isValidatePhoneNumber: false,
                isValidateEmail: false,
                isValidateName: false,
                isValidatePromoSlug: false,
              },
            },
          })),
      },
      PAGEMODE: {
        currentPageModeState: "null",
        setCurrentPageModeState: (newCurrentPageModeState) =>
          set((state) => ({
            ...state,
            PAGEMODE: {
              ...state.PAGEMODE,
              currentPageModeState: newCurrentPageModeState,
            },
          })),
      },
      NAMECARD_OPERATION: {
        nameCardOperationState: {
          action: "create",
          currentNameCardType: "null",
          selectNameCardType: "null",
        },
        setNameCardOperationState: (newNameCardOperationState) =>
          set((state) => ({
            ...state,
            NAMECARD_OPERATION: {
              ...state.NAMECARD_OPERATION,
              nameCardOperationState: {
                ...state.NAMECARD_OPERATION.nameCardOperationState,
                ...newNameCardOperationState,
              },
            },
          })),
      },
      NAMECARD_DATA: {
        nameCardData: {
          nameCardV1Data: [JSON.parse(JSON.stringify(initNameCardV1Data))],
          nameCardV1Setting: JSON.parse(JSON.stringify(initNameCardV1Setting)),
          nameCardV2Data: null,
          nameCardV3Data: [JSON.parse(JSON.stringify(initNameCardV3Data))],
          nameCardV3Setting: JSON.parse(JSON.stringify(initNameCardV3Setting)),
        },
        setNameCardV1Data: (newNameCardV1Data: nameCardV1DataType[]) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1Data: newNameCardV1Data,
              },
            },
          })),
        initNameCardV1Data: () =>
          set((state) => ({
            ...state,
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1Data: [
                  JSON.parse(JSON.stringify(initNameCardV1Data)),
                ],
              },
            },
          })),
        initNameCardV1Setting: () =>
          set((state) => ({
            ...state,
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1Setting: JSON.parse(
                  JSON.stringify(initNameCardV1Setting)
                ),
              },
            },
          })),
        setNameCardV1Setting: (newNameCardV1Setting) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1Setting: {
                  ...state.NAMECARD_DATA.nameCardData.nameCardV1Setting,
                  ...newNameCardV1Setting,
                },
              },
            },
          })),

        setNameCardV2Data: (newNameCardV2Data) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV2Data: newNameCardV2Data,
              },
            },
          })),

        setNameCardV3Data: (newNameCardV3Data: CFlexBubble[]) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3Data: newNameCardV3Data,
              },
            },
          })),
        initNameCardV3Data: () =>
          set((state) => ({
            ...state,
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3Data: [
                  JSON.parse(JSON.stringify(initNameCardV3Data)),
                ],
              },
            },
          })),
        initNameCardV3Setting: () =>
          set((state) => ({
            ...state,
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3Setting: JSON.parse(
                  JSON.stringify(initNameCardV3Setting)
                ),
              },
            },
          })),
        setNameCardV3Setting: (newNameCardV3Setting) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3Setting: {
                  ...state.NAMECARD_DATA.nameCardData.nameCardV3Setting,
                  ...newNameCardV3Setting,
                },
              },
            },
          })),
      },
      MEMBERCHECK: {
        isMemberCheck: false,
        setIsMemberCheck: (_isMemberCheck: boolean) =>
          set((state) => ({
            MEMBERCHECK: {
              ...state.MEMBERCHECK,
              isMemberCheck: _isMemberCheck,
            },
          })),
      },
      BODYBLOCKREF: {
        bodyBlockRef: null,
        setbBdyBlockRef: (_bodyBlockRef: HTMLDivElement) =>
          set((state) => ({
            BODYBLOCKREF: {
              ...state.BODYBLOCKREF,
              bodyBlockRef: _bodyBlockRef,
            },
          })),
      },
      USER_BONUS: {
        userBonusState: {
          y4m2: undefined,
          date_start: undefined,
          date_end: undefined,
          isUserBonus: false,
        },
        setUserBonusState: (newUserBonusState) =>
          set((state) => ({
            ...state,
            USER_BONUS: {
              ...state.USER_BONUS,
              userBonusState: {
                ...state.USER_BONUS.userBonusState,
                ...newUserBonusState,
              },
            },
          })),
      },
      NavListHeader: {
        isOpen: false,
        toggle: () =>
          set((state) => ({
            ...state,
            NavListHeader: {
              ...state.NavListHeader,
              isOpen: !state.NavListHeader.isOpen,
            },
          })),
        close: () =>
          set((state) => ({
            ...state,
            NavListHeader: { ...state.NavListHeader, isOpen: false },
          })),
      },
    }),
    {
      name: "IPFlow-storage",
      getStorage: () => sessionStorage, // 配置本地存储，可以选择 sessionStorage 或其他存储方式
    }
  )
  // )
);
