export const fontSize = [
  "xxs",
  "sx",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl",
  "3xl",
  "4xl",
  "5xl",
];
export const buttonFontSize = ["sm", "md"];
