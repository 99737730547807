import classes from "./SideBar.module.sass";
import { useEffect, useState, useRef } from "react";
import { FolderFilled, PlusOutlined } from "@ant-design/icons";
import profileIconSelect from "../../image/icon-profile-select.svg";
import profileIconNomal from "../../image/icon-profile-nomal.svg";
import userBonusIconSelect from "../../image/icon-user-bonus-select.svg"; // 24.11.02 SCH ★
import userBonusIconNomal from "../../image/icon-user-bonus-nomal.svg"; // 24.11.02 SCH ★
import NameCardTypeModal from "./NameCardTypeModal";
import CancelEditModal from "./CancelEditModal";
import { useNavigate } from "react-router-dom";

import { useAppStateStore } from "../../store/appState";

import { Modal, Button, Form, Input } from "antd"; // 24.11.04 SCH ★

import { format } from "date-fns"; // 24.11.04 SCH ★

export interface INameCardType {
  selectNameCardType: "nameCard1.0" | "fullImgMode" | "null";
}

type inputHandelerType = {
  key: "Y4M2" | string; // 24.11.05 SCH
  value: string;
};

const SideBar = () => {
  const { PAGEMODE, NAMECARD_OPERATION } = useAppStateStore();
  const { currentPageModeState, setCurrentPageModeState } = PAGEMODE;
  const { nameCardOperationState, setNameCardOperationState } =
    NAMECARD_OPERATION;
  const { selectNameCardType, action } = nameCardOperationState;
  const [nameCardTypeModalOpen, setNameCardTypeModalOpen] =
    useState<boolean>(false);
  const [cancelEditModalOpen, setCancelEditModalOpen] =
    useState<boolean>(false);
  const [routerPath, setRouterPath] = useState<string>("/");

  const { BRAND, USER, USER_BONUS } = useAppStateStore(); // 24.11.04 SCH
  const { brandState } = BRAND; // 24.11.02 SCH ★
  const { userState } = USER; // 24.11.02 SCH
  const {
    role,
    eagle,
    enable,
    editable,
    trial_due_at,
    mobile_number,
    name_card_count,
  } = userState; // 24.11.05 SCH
  const [trialDueAt, setTrialDueAt] = useState(""); // 24.11.02 SCH ★
  const [isAddBanModalOpen, setIsAddBanModalOpen] = useState(false); // 24.11.05 SCH ★
  const [isEditBanModalOpen, setIsEditBanModalOpen] = useState(false); // 24.11.02 SCH ★
  const [isUserBonusBanModalOpen, setIsUserBonusBanModalOpen] = useState(false); // 24.11.02 SCH ★
  const [isBonusY4M2ModalOpen, setIsBonusY4M2ModalOpen] = useState(false); // 24.11.04 SCH ★
  const { userBonusState, setUserBonusState } = USER_BONUS; // 24.11.04 SCH ★
  const isValidateY4M2 = useRef<boolean>(true); // 24.11.14 SCH ★

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPageModeState("profile");
  }, []);

  const closeNameCardTypeModalHandler = () => {
    setNameCardTypeModalOpen(false);
    setNameCardOperationState({ selectNameCardType: "null" });
  };

  const closeCancelEditModalHandler = () => {
    setCancelEditModalOpen(false);
    setNameCardOperationState({ selectNameCardType: "null" });
  };

  const openNameCardTypeModalHandler = () => {
    if (
      currentPageModeState === "createNameCard" ||
      currentPageModeState === "createNameCardFullImgMode" ||
      currentPageModeState === "updateNameCard" ||
      currentPageModeState === "updateNameCardFullImgMode"
    ) {
      return setCancelEditModalOpen(true);
    }
    setNameCardTypeModalOpen(true);

    if (selectNameCardType === "null") return;
    if (selectNameCardType === "v1.0") {
      navigate("/createNameCard");
    } else if (selectNameCardType === "v3.0") {
      navigate("/createFullImgMode");
    }
  };

  const profileOnClickHandler = () => {
    if (currentPageModeState === "profile") return;
    if (currentPageModeState === "nameCardsList") return navigate("/profile");
    if (
      currentPageModeState === "createNameCard" ||
      currentPageModeState === "createNameCardFullImgMode" ||
      currentPageModeState === "updateNameCard" ||
      currentPageModeState === "updateNameCardFullImgMode"
    ) {
      setCancelEditModalOpen(true);
      setRouterPath("/profile");
      setNameCardOperationState({ action: "goProfile" });
    }
  };

  // 24.11.05 SCH ★
  const userBonusOnClickHandler = () => {
    if (currentPageModeState === "userBonus") return;
    if (currentPageModeState === "nameCardsList") return navigate("/userBonus");
    if (
      currentPageModeState === "profile" ||
      currentPageModeState === "createNameCard" ||
      currentPageModeState === "createNameCardFullImgMode" ||
      currentPageModeState === "updateNameCard" ||
      currentPageModeState === "updateNameCardFullImgMode"
    ) {
      setCancelEditModalOpen(true);
      setUserBonusState({ isUserBonus: true }); // 24.11.05 SCH ★
      setRouterPath("/userBonus");
      setNameCardOperationState({ action: "goUserBonus" });
    }
  };

  const reCreateEventModalHandler = () => {
    setNameCardOperationState({ action: "null" });
    setNameCardTypeModalOpen(true);
  };

  useEffect(() => {
    let trialDueAt = trial_due_at?.toString();
    if (trialDueAt) setTrialDueAt(trialDueAt); // 24.11.02 SCH ★
    let now_month = format(new Date(), "yyyy-MM");
    setUserBonusState({ y4m2: now_month }); // 24.11.07 SCH ★
  }, [trialDueAt, trial_due_at, setUserBonusState]);

  useEffect(() => {
    if (selectNameCardType === "null") return;
    if (selectNameCardType === "v1.0") {
      navigate("/createNameCard");
    } else if (selectNameCardType === "v3.0") {
      navigate("/createFullImgMode");
    }
  }, [selectNameCardType]);

  const nameCardOnClickHandler = () => {
    if (currentPageModeState === "nameCardsList") return;
    if (currentPageModeState === "profile") return navigate("/");
    if (
      currentPageModeState === "createNameCard" ||
      currentPageModeState === "createNameCardFullImgMode" ||
      currentPageModeState === "updateNameCard" ||
      currentPageModeState === "updateNameCardFullImgMode"
    ) {
      setCancelEditModalOpen(true);
      setRouterPath("/");
      setNameCardOperationState({ action: "goNameCardList" });
    }
  };

  const AddBanHandleOk = () => {
    setIsAddBanModalOpen(false);
  };

  const AddBanHandleCancel = () => {
    setIsAddBanModalOpen(false);
  };

  const EditBanHandleOk = () => {
    setIsEditBanModalOpen(false);
  };

  const EditBanHandleCancel = () => {
    setIsEditBanModalOpen(false);
  };

  const UserBonusBanHandleOk = () => {
    setIsUserBonusBanModalOpen(false);
  };

  const UserBonusBanHandleCancel = () => {
    setIsUserBonusBanModalOpen(false);
  };

  const BonusY4M2HandleOk = () => {
    setIsBonusY4M2ModalOpen(false);
    userBonusOnClickHandler(); // 24.11.04 SCH
  };

  const BonusY4M2HandleCancel = () => {
    setIsBonusY4M2ModalOpen(false);
    setUserBonusState({ y4m2: undefined }); // 24.11.04 SCH
  };

  const inputHandeler = ({ key, value }: inputHandelerType) => {
    switch (key) {
      case "Y4M2": // 24.11.07 SCH ★
        validateY4M2(value) && setUserBonusState({ y4m2: value });
        console.log("inputHandeler.y4m2 =", value);
        break;

      default:
        break;
    }
  };

  const y4m2_Rule = /^([0-9])*[0-9]+-([0-9])*[0-9]$/; // 24.11.05 SCH ★

  const validateY4M2 = (value: string) => {
    // slice(2,4) === substring(2,4); (the end byte not taked！)
    let y2 = value.slice(2, 4); // 24.11.05 SCH ★
    console.log("y2 =", y2); // 24.11.05 SCH ★
    if (
      value &&
      value.length === 7 &&
      value[4] === "-" &&
      value.search(y4m2_Rule) !== -1 &&
      value.slice(0, 2) === "20" &&
      y2 >= "23" &&
      y2 <= "99" &&
      value.slice(5) >= "01" &&
      value.slice(5) <= "12"
    ) {
      isValidateY4M2.current = true;
      console.log("isValidateY4M2.current =", true);
      return true; // 24.11.05 SCH ★
    } else {
      if (!value || value.length === 0) {
        isValidateY4M2.current = true;
        console.log("isValidateY4M2.current =", true);
        return true; // 24.11.14 SCH ★
      } else {
        isValidateY4M2.current = false;
        console.log("isValidateY4M2.current =", false);
        return false; // 24.11.05 SCH ★
      }
    }
  };

  return (
    <>
      <div
        className={
          (currentPageModeState !== "nameCardsList"
            ? classes.noFoorerHeight
            : classes.noFoorerHeight) +
          " " +
          classes.sideBarContainer
        }
      >
        <ul>
          <div
            onClick={nameCardOnClickHandler}
            className={
              (currentPageModeState === "nameCardsList"
                ? classes.selectColor
                : classes.nomalColor) +
              " " +
              classes.buttonDefault
            }
          >
            <FolderFilled width={32} rev={""} />
          </div>

          <li>
            <div
              onClick={() => {
                //目前名片數量大於此會員可以新增的名片數量
                if (name_card_count >= brandState.trial_name_cards) {
                  setIsEditBanModalOpen(true);
                }
                //是 付費會員 or 可新增名片權限 or 老鷹推廣夥伴
                else if (role >= 2 || enable || eagle) {
                  // 24.11.02 SCH
                  openNameCardTypeModalHandler();
                  setRouterPath("/");
                  setNameCardOperationState({ action: "reCreate" });
                } else {
                  // 個人資料是否有填手機號碼
                  if (mobile_number === null || mobile_number === "") {
                    setIsAddBanModalOpen(true); // 24.11.05 SCH
                  } else {
                    setIsEditBanModalOpen(true); // 24.11.02 SCH
                  }
                }
              }}
              className={
                (currentPageModeState === "createNameCard" ||
                currentPageModeState === "createNameCardFullImgMode" ||
                currentPageModeState === "updateNameCard" ||
                currentPageModeState === "updateNameCardFullImgMode"
                  ? classes.selectColor
                  : classes.nomalColor) +
                " " +
                classes.buttonDefault
              }
            >
              <PlusOutlined width={32} rev={""} />
            </div>
          </li>
          <li>
            <div>
              <div
                className={classes.buttonDefault}
                onClick={profileOnClickHandler}
              >
                <img
                  src={
                    currentPageModeState === "profile"
                      ? profileIconSelect
                      : profileIconNomal
                  }
                  alt="profile"
                />
              </div>
            </div>
          </li>
          <li>
            <div>
              <div
                className={classes.buttonDefault}
                onClick={() => {
                  if (role >= 2 || eagle) {
                    setIsBonusY4M2ModalOpen(true); // 24.11.04 SCH
                  } else {
                    setIsUserBonusBanModalOpen(true); // 24.11.02 SCH
                  }
                }}
              >
                <img
                  src={
                    currentPageModeState === "userBonus"
                      ? userBonusIconSelect
                      : userBonusIconNomal
                  }
                  alt="userBonus"
                />
              </div>
            </div>
          </li>
        </ul>
        <NameCardTypeModal
          isOpen={nameCardTypeModalOpen}
          closeNameCardTypeModalHandler={closeNameCardTypeModalHandler}
        />
        <CancelEditModal
          isOpen={cancelEditModalOpen}
          routerPath={routerPath}
          closeCancelEditModalHandler={closeCancelEditModalHandler}
          reCreateEventModalHandler={reCreateEventModalHandler}
        />
      </div>

      <Modal
        title="編輯權益限制提醒"
        open={isAddBanModalOpen}
        onOk={AddBanHandleOk}
        onCancel={AddBanHandleCancel}
      >
        <p>無法新增名片，您尚未完成輸入個人資料喔！</p>
      </Modal>

      <Modal
        title="編輯權益限制提醒"
        open={isEditBanModalOpen}
        onOk={EditBanHandleOk}
        onCancel={EditBanHandleCancel}
      >
        <p>
          無法新增名片，您現持有張數已滿免費試用數（
          {`${brandState.trial_name_cards}`}）喔！
        </p>
      </Modal>

      <Modal
        title="查詢權益限制提醒"
        open={isUserBonusBanModalOpen}
        onOk={UserBonusBanHandleOk}
        onCancel={UserBonusBanHandleCancel}
      >
        <p>
          您是一般會員，無聯盟行銷推廣佣金資料喔！您可在〔個人資料〕的頁面看到〈付費解鎖進階功能〉的按鈕。
        </p>
      </Modal>

      <Modal
        title="指定佣金所屬年月"
        open={isBonusY4M2ModalOpen}
        onOk={BonusY4M2HandleOk}
        onCancel={BonusY4M2HandleCancel}
      >
        <div>
          <Form.Item
            label={<span>佣金所屬年月：</span>}
            validateStatus={isValidateY4M2.current ? "success" : "error"}
            hasFeedback
          >
            <Input
              type="text"
              name="Y4M2"
              allowClear
              placeholder="請輸入佣金所屬年月：yyyy-mm"
              defaultValue={format(new Date(), "yyyy-MM")}
              onChange={(e) => {
                inputHandeler({ key: e.target.name, value: e.target.value });
              }}
            ></Input>
            <p></p>
            <p>清空上列欄位內容，則顯示全部資料。</p>
          </Form.Item>
        </div>
      </Modal>
    </>
  );
};
export default SideBar;
