import { NavLink } from "react-router-dom";
import classes from "./Header.module.sass";
import { Layout } from "antd";
import logo from "../../image/logo.svg";
import NameCardsListHeader from "./NameCardsListHeader";
import { useAppStateStore } from "../../store/appState";

const Header = () => {
  const { Header } = Layout;
  const { NavListHeader } = useAppStateStore();
  const { close } = NavListHeader; // 讓 NameCardsListHeader nav 關閉
  return (
    <Header className={classes.Header}>
      <div className={classes.col} onClick={close}>
        {" "}
        {/*  讓 NameCardsListHeader nav 關閉 */}
        <NavLink to="/">
          <img src={logo} alt="logo" />
        </NavLink>
      </div>
      <div className={classes.col}>
        <div className={classes.nameCardsListHeader}>
          <NameCardsListHeader />
        </div>
      </div>
    </Header>
  );
};
export default Header;
