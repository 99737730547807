import { config, configType } from "../config";

export const getCurrentConfig = () => {
  const environment = process.env.REACT_APP_ENV || "dev";
  const currentConfig = config[environment];

  if (!currentConfig) {
    console.error(`Config for environment ${environment} not found.`);
  }

  return currentConfig;
};
