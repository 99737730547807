import { Button } from "antd";
import classes from "../Footer.module.sass";
import { useAppStateStore } from "../../../store/appState";
import liff from "@line/liff";

const MemberCheckFooter = () => {
  const { MEMBERCHECK } = useAppStateStore();
  const { isMemberCheck } = MEMBERCHECK;

  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      {isMemberCheck && (
        <div className={classes.buttonItem}>
          <Button
            type="primary"
            className={classes.button}
            onClick={() => liff.closeWindow()}
          >
            <div className={classes.buttonWord}>關閉</div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default MemberCheckFooter;
