import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useLocation } from "react-router-dom";
import liff from "@line/liff";
import {
  queryNameCardByHashId,
  queryNameCardsByMobileNumber,
  queryThisNameCard,
  queryNameCards,
  nameCardType,
  updateNameCards,
  addNameCards,
} from "../../api";
import { useShareFlexMsg } from "../../hooks/useShareFlexMsg";
import { useAppStateStore } from "../../store/appState";
import Loading from "../Loading";

const EagleSendFlexMsg = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApiAvailableModalOpen, setIsApiAvailableModalOpen] =
    useState<boolean>(false);
  const { USER, APP } = useAppStateStore();
  const { accessToken, mobile_number } = USER.userState;
  const { liffState } = APP;
  const { lineIDToken } = liffState;
  const [loading, setLoading] = useState<boolean>(true);

  const { shareNameCard, error, isApiAvailable, sendFlexMsgStatus } =
    useShareFlexMsg();

  useEffect(() => {
    if (sendFlexMsgStatus === null) return;
    if (sendFlexMsgStatus) {
      setLoading(false);
      setTimeout(() => {
        liff.closeWindow();
      }, 1000);
      console.log("sendFlexMsgStatus", sendFlexMsgStatus);
    } else {
      setIsModalOpen(true);
    }
  }, [sendFlexMsgStatus]);

  useEffect(() => {
    if (isApiAvailable) {
      liff.closeWindow();
    }
  }, [isApiAvailable]);

  const location = useLocation();
  // 從路徑資訊中取得查詢參數
  const searchParams = new URLSearchParams(location.search);
  // 從查詢參數中獲取名為 'mobile_number' 的值
  const hashId = searchParams.get("hash_id");
  console.log(hashId);

  //  using test flex message
  const temp = {};

  const handleOk = () => {
    setIsModalOpen(false);
    liff.closeWindow();

    // 在這裡關閉 LIFF
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    // 在這裡關閉 LIFF
    liff.closeWindow();
  };

  const cancelIsApiAvailableModalHandler = () => {
    setIsApiAvailableModalOpen(false);

    // 在這裡關閉 LIFF
    liff.closeWindow();
  };

  const okIsApiAvailableModalHandler = () => {
    setIsApiAvailableModalOpen(false);

    // 在這裡關閉 LIFF
    liff.closeWindow();
  };

  const QueryNameCards = async () => {
    try {
      if (accessToken) {
        const { data: res } = await queryNameCards(accessToken);
        if (res) {
          const { data } = res;

          // setNameCardData(data);
          // console.log(data);
          // 成功獲取名片資料後，調用發送 Flex Message 函數
          if (lineIDToken) {
            sendFlxMsgHandler(data[0].card_title, data[0].card_content);
          }

          return data;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const QueryNameCardsByMobileNumber = async () => {
    try {
      if (accessToken) {
        if (mobile_number) {
          console.log(mobile_number);
          const { data: res } = await queryNameCardsByMobileNumber(
            accessToken,
            mobile_number
          );
          if (res) {
            const { data } = res;
            console.log(data);
            // 成功獲取名片資料後，調用發送 Flex Message 函數
            if (lineIDToken) {
              sendFlxMsgHandler(data[0].card_title, data[0].card_content);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const QueryNameCardByHashId = async (_hashId: string) => {
    try {
      if (accessToken && _hashId !== "" && _hashId !== null) {
        const { data: res } = await queryNameCardByHashId(accessToken, _hashId);
        console.log(res);
        if (res) {
          const { data } = res;
          console.log(data);
          if (data.length === 0) {
            setIsModalOpen(true);
            return;
          }
          if (lineIDToken) {
            shareNameCard(`${data[0].card_id}`);
            // sendFlxMsgHandler(data[0].card_title, data[0].card_content);
          }
        }
      }
    } catch (error) {
      console.log("error", JSON.stringify(error));
    }
  };

  const sendFlxMsgHandler = async (
    _card_title: string,
    _card_content: string
  ) => {
    if (!liff.isApiAvailable("shareTargetPicker")) {
      setIsApiAvailableModalOpen(true);
      return;
    }

    try {
      const res = await liff.shareTargetPicker(
        [
          {
            type: "flex",
            altText: _card_title,
            contents: JSON.parse(_card_content),
          },
        ],
        {
          isMultiple: true,
        }
      );

      if (res) {
        console.log(`[${res.status}] Message sent!`);
        setLoading(false);
        // 在這裡關閉 LIFF
        setTimeout(() => {
          liff.closeWindow();
        }, 1500);
      } else {
        const [majorVer, minorVer] = (liff.getLineVersion() || "").split(".");
        if (parseInt(majorVer) === 10 && parseInt(minorVer) < 11) {
          console.log(
            "TargetPicker was opened at least. Whether succeeded to send message is unclear"
          );
        } else {
          console.log("TargetPicker was closed!");
        }
      }
    } catch (error) {
      // alert('傳送訊息失敗');
      console.log("發生錯誤");
      setLoading(false);
      console.error(JSON.stringify(error));
      setIsModalOpen(true);
      return;
    }
  };

  useEffect(() => {
    // QueryNameCardsByMobileNumber();
    if (hashId) {
      QueryNameCardByHashId(hashId);
    }
  }, [accessToken, hashId]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {error ? "發送名片失敗" : "發送名片完成"}
        </div>
      )}
      {/* <button onClick={UpdateNameCards}>修改名片資料</button> */}
      {/* <button onClick={AddNameCard}>新增名片資料</button> */}
      {/* {nameCardData && <button onClick={sendFlxMsgHandler}>點此按鈕發送名片</button>} */}
      <Modal
        title="提醒"
        open={isApiAvailableModalOpen}
        onOk={okIsApiAvailableModalHandler}
        onCancel={cancelIsApiAvailableModalHandler}
      >
        <p>抱歉，此裝置沒有支援 line liff 分享功能 </p>
      </Modal>

      <Modal
        title="提醒"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>名片發送途中發生錯誤</p>
      </Modal>
    </div>
  );
};

export default EagleSendFlexMsg;
