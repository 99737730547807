import { Card, Button } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "./PayCancel.module.sass";
import { useEffect } from "react";
import { useAppStateStore } from "../../store/appState";

const PayCancel = () => {
  const navigate = useNavigate();
  const goProfilePage = () => {
    navigate("/profile");
  };
  const { PAGEMODE } = useAppStateStore();
  const { setCurrentPageModeState } = PAGEMODE;

  useEffect(() => {
    setCurrentPageModeState("null");
  }, []);
  return (
    <div className={classes.container}>
      <div className={classes.button}>
        <Card title="付款失敗" style={{ width: 300 }}>
          <p>線上付款失敗，點擊以下按鈕返回會員資料頁面</p>
          <Button type="primary" ghost onClick={goProfilePage}>
            <div className={classes.buttonWord}>返回</div>
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default PayCancel;
