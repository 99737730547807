import { FlexBox, FlexText } from "@line/bot-sdk/dist/types";

export const fullImgModeNameCardAltUrlConfig = {
  urlButton: { desktop: "http://urlButton/desktop" },
  shareNameCardButton: { desktop: "http://shareNameCardButton/desktop" },
  phoneButton: { desktop: "https://callPhoneButton/desktop" },
};

export const fullImgModeNameCardButtonType = {
  urlButton: {
    type: "text",
    text: "urlButton",
    color: "#00000000",
  } as FlexText,
  shareNameCardButton: {
    type: "text",
    text: "shareNameCardButton",
    color: "#00000000",
  } as FlexText,
  callphoneButton: {
    type: "text",
    text: "callPhoneButton",
    color: "#00000000",
  } as FlexText,
};

export const fullImgModeNameCardButtonLabelConfig = {
  urlButton: "",
  shareNameCardButton: "分享名片",
  phoneButton: "聯絡電話",
};

export const fullImgModeNameCardConfig = {
  urlButton: {
    type: "box",
    layout: "vertical",
    contents: [fullImgModeNameCardButtonType.urlButton],
    action: {
      type: "uri",
      label: fullImgModeNameCardButtonLabelConfig.urlButton,
      uri: "http://linecorp.com/",
      // altUri: { desktop: "http://linecorp.com/" },
    },
    backgroundColor: "#00000000",
    width: "150px",
    height: "50px",
    position: "absolute",
    offsetTop: "0px",
    offsetStart: "0px",
  } as FlexBox,

  shareNameCardButton: (
    cardHashID: string | undefined,
    liffid: string
  ): FlexBox => {
    return {
      type: "box",
      layout: "vertical",
      contents: [fullImgModeNameCardButtonType.shareNameCardButton],
      action: {
        type: "uri",
        label: fullImgModeNameCardButtonLabelConfig.shareNameCardButton,
        uri: cardHashID
          ? `https://liff.line.me/${liffid}/eaglesendflexMsg?hash_id=${cardHashID}`
          : `https://liff.line.me/${liffid}/eaglesendflexMsg?hash_id=`,
      },
      backgroundColor: "#00000000",
      width: "150px",
      height: "50px",
      position: "absolute",
      offsetTop: "0px",
      offsetStart: "0px",
    };
  },

  phoneButton: {
    type: "box",
    layout: "vertical",
    contents: [fullImgModeNameCardButtonType.callphoneButton],
    action: {
      type: "uri",
      label: fullImgModeNameCardButtonLabelConfig.phoneButton,
      uri: "",
    },
    backgroundColor: "#00000000",
    width: "150px",
    height: "50px",
    position: "absolute",
    offsetTop: "0px",
    offsetStart: "0px",
  } as FlexBox,
};
