import liff from "@line/liff";
import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import classes from "./SendFlexMessageFromJson.module.sass";

const { TextArea } = Input;

const SendFlexMessageFromJson = (): JSX.Element => {
  const [inputVal, setInputVal] = useState<string | null>(null);

  const sendFlxMsgHandler = async () => {
    if (!liff.isApiAvailable("shareTargetPicker")) {
      alert("無法發送訊息");
      return;
    }

    if (inputVal !== null) {
      console.log(JSON.parse(inputVal));
      try {
        const res = await liff.shareTargetPicker(
          [
            {
              type: "flex",
              altText: "This is a Flex Message",
              contents: JSON.parse(inputVal),
            },
          ],
          {
            isMultiple: true,
          }
        );

        if (res) {
          console.log(`[${res.status}] Message sent!`);
        } else {
          const [majorVer, minorVer] = (liff.getLineVersion() || "").split(".");
          if (parseInt(majorVer) === 10 && parseInt(minorVer) < 11) {
            console.log(
              "TargetPicker was opened at least. Whether succeeded to send message is unclear"
            );
          } else {
            console.log("TargetPicker was closed!");
          }
        }
      } catch (error) {
        console.log("發生錯誤");
        console.error(JSON.stringify(error));
      }
    }
  };
  useEffect(() => {
    console.log(inputVal);
  }, [inputVal]);
  return (
    <div className={classes.container}>
      <h3>使用 JSON 發送 Line Flex Message</h3>
      <div className={classes.buttonContainer}>
        <Button
          type="primary"
          className={classes.button}
          onClick={sendFlxMsgHandler}
        >
          <span style={{ textAlign: "center", fontSize: "16px" }}>
            發送 Flex Message
          </span>
        </Button>
      </div>

      <div className={classes.textareaContainer}>
        <label htmlFor=""> 輸入你的 Flex Message Json </label>
        <TextArea
          cols={10}
          rows={15}
          onChange={(e) => setInputVal(e.target.value)}
          placeholder="在這邊輸入要發送的 Flex Message Json"
        ></TextArea>
      </div>
    </div>
  );
};

export default SendFlexMessageFromJson;
