import { keyword2PxConfig } from "../config/flexMessageSettingConfig";

const useKeyword2Px = () => {
  const fontSize2Px = (value: string) => {
    const index = keyword2PxConfig.findIndex(
      (keyword) => keyword.keyWord === value
    );
    const isKeyword = index === -1 ? false : true;
    const px = isKeyword ? keyword2PxConfig[index].value : value;
    return px;
  };
  return { fontSize2Px };
};

export default useKeyword2Px;
